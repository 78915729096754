import React from 'react'
import './boxContainer.css'

const BoxContainer = ({children}) => {
    return (
      <div className='box-container'>
          {children}
      </div>
    )
  }

export default BoxContainer