import React, {useState, useRef} from 'react'
import { useNavigate } from 'react-router-dom'
import './login.css'
import { useAuth } from '../../contexts/AuthContext'

const Login = () => {
    let [loading, setLoading] = useState(false);
    let [error, setError] = useState('')
    let [isError, setIsError] = useState(false)
    let loginEmailRef = useRef();
    let loginPasswordRef = useRef();
    let navigate = useNavigate()

    const { currentUser, login } = useAuth()


    async function handlelogin(event){
        event.preventDefault()
        try{
            setError("")
            setLoading(true)
            await login(loginEmailRef.current.value, loginPasswordRef.current.value)
            
            console.log(currentUser)
            navigate('/dashboard')
            // if(currentUser){
            // }

        }catch(err){
            setIsError(true)
            console.log(err.code)
            if(err.code === 'auth/email-already-in-use')
                setError("email already in use")
            if(err.code === 'auth/wrong-password')
                setError("wrong password")
            else
                setError(err)
        }
        setLoading(false)
    }
    
  return (
    <>
        <div className="login-page">
            <form className="login-input" name="login-form" onSubmit={handlelogin}>
                {isError && 
                    <h1 style={{color: 'darkred'}}>{error}</h1>
                }
                <input required name='login-email' type='text' ref={loginEmailRef} placeholder="Email"/>
                <input required name='login-password' type='password' ref={loginPasswordRef} placeholder="Password"/>
                <button type="submit" disabled={loading}>Log in</button>
            </form>
            
        </div>
    </>
  )
}

export default Login