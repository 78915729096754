import React from 'react'
import './energySaving.css'
import pic from '../../assets/0JudETPidY_TXQCOSzodY.jpg'
import Container from '../../components/container/Container'
const EnergySaving = () => {
  return (
    <section class="energy-insights section-style">
        <Container>
            <div class="insights-image">
                <img src={pic} alt="Energy Saving App"/>
            </div>

            <div class="insights-content">
                <h2 class="heading">Energy-Saving Insights and Analytics</h2>
                
                <div className='card-container'>
                    <div class="insight-card">
                        <h3>Detailed Reports</h3>
                        <p>Track your energy consumption and identify areas where you can save.</p>
                    </div>

                    <div class="insight-card">
                        <h3>Personalized Recommendations</h3>
                        <p>Receive tailored suggestions for optimizing your heating and cooling settings.</p>
                    </div>
                </div>

                <div class="insight-card">
                    <h3>Savings Potential</h3>
                    <p>Estimate your potential savings by using our energy-saving features and recommendations.</p>
                </div>
            </div>
        </Container>
  </section>
  )
}

export default EnergySaving