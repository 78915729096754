import React from 'react'
import Container from '../../components/container/Container'
import './hero.css'
import thermostat from "../../assets/N1pOhQw8dgwrivbYTXIxv.jpg"

const Hero = () => {
    return (
        <section className="hero-section section-style">
            <Container>
                {/* Left Side: Text Content */}
                <div className="text-content">
                    <h1 className="heading">Smart Home Thermostat: Optimize Comfort and Savings</h1>
                    <p>
                    Take control of your home's climate with our intelligent thermostat. Enjoy personalized comfort, effortless control, and energy-saving insights all in one device.
                    </p>
                    {/* <p>🔥 Limité aux 500 premières demandes !</p> */}
                    <div className="cta-buttons">
                        <a href="#request-form" className="gradient-link-button">
                        <span> order yours now!</span>
                        </a>
                    </div>
                </div>

                {/* Right Side: Image/Visual */}
                <div className="image-content">
                    <img
                        src={thermostat}
                        alt="Connected Thermostat"
                        className="max-w-full h-auto"
                    />
                </div>
            </Container>
        </section>
    )
}

export default Hero