import React from 'react'
import Container from '../../components/container/Container'
import './howItWork.css'

const HowItWork = () => {
    return (
        <section className='how-it-work section-style'>
            <Container>
                <h2 className='heading'>How Our Smart Thermostat Works</h2>
                <div className="inner-container">
                    <div className="box">
                        <h3 className='heading small-heading'>Learning Your Preferences</h3>
                        <p>The thermostat learns your preferred temperatures and schedules, automatically adjusting for optimal comfort.</p>
                        <h3 className='heading small-heading'>Learning Your Preferences</h3>
                        <p>Set up custom schedules to adjust temperatures based on your daily routine, ensuring you're never too hot or too cold..</p>
                    </div>
                    <div className="box">
                        <video controls>
                            <source src="thermostat-demo.mp4" type="video/mp4" />
                            Votre navigateur ne supporte pas la lecture vidéo.
                        </video>
                    </div>
                </div>

                
                {/* <div className='how-it-works-image'>
                    <img src={pic} alt='How It Works'/>
                </div> */}

                
            </Container>
        </section>
    )
}

export default HowItWork