import React from 'react'
import BoxContainer from '../../components/boxContainer/BoxContainer'
import './accessControl.css'

const AccessControl = () => {
    return (
        <section className='access-control section-style box'>
            <BoxContainer>
                <h2 className='heading'>Remote Access and Control</h2>
                <div className='inner-container'>
                    <div className='box'>
                        <div className='icon'>
                            <div className='icon-wrapper'>
                                <svg class="colorable-icon" viewBox="0 0 384 512" xmlns="http://www.w3.org/2000/svg" data-icon="location-dot" data-prefix="fal" aria-hidden="true">
                                    <defs>
                                        <linearGradient id="myGradient" x1="0%" y1="0%" x2="100%" y2="100%">
                                        <stop offset="0%" stopColor="#9177D9" stopOpacity="1" />
                                        <stop offset="100%" stopColor="#5A41A2" stopOpacity="1" />
                                        </linearGradient>
                                    </defs>
                                    <path d="M352 192c0-88.4-71.6-160-160-160S32 103.6 32 192c0 15.6 5.4 37 16.6 63.4c10.9 25.9 26.2 54 43.6 82.1c34.1 55.3 74.4 108.2 99.9 140c25.4-31.8 65.8-84.7 99.9-140c17.3-28.1 32.7-56.3 43.6-82.1C346.6 229 352 207.6 352 192zm32 0c0 87.4-117 243-168.3 307.2c-12.3 15.3-35.1 15.3-47.4 0C117 435 0 279.4 0 192C0 86 86 0 192 0S384 86 384 192zm-240 0a48 48 0 1 0 96 0 48 48 0 1 0 -96 0zm48 80a80 80 0 1 1 0-160 80 80 0 1 1 0 160z"></path>
                                </svg>
                            </div>
                        </div>
                        <h3 className='small-heading'>Secure Remote Access</h3>
                        <p>Control your thermostat from anywhere in the world, using your smartphone or tablet.</p>
                    </div>
                    <div className='box'>
                        <div className='icon'>
                            <div className='icon-wrapper'>
                            <svg class="colorable-icon" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg" data-icon="phone-volume" data-prefix="fal" aria-hidden="true"><path d="M272 0C404.5 0 512 107.5 512 240c0 8.8-7.2 16-16 16s-16-7.2-16-16c0-114.9-93.1-208-208-208c-8.8 0-16-7.2-16-16s7.2-16 16-16zm16 192a32 32 0 1 1 0 64 32 32 0 1 1 0-64zm-32-80c0-8.8 7.2-16 16-16c79.5 0 144 64.5 144 144c0 8.8-7.2 16-16 16s-16-7.2-16-16c0-61.9-50.1-112-112-112c-8.8 0-16-7.2-16-16zm73 174.7c11.3-13.8 30.3-18.5 46.7-11.4l112 48c17.6 7.5 27.4 26.5 23.4 45.1l-24 112c-4 18.4-20.3 31.6-39.1 31.6l0 0c-6.1 0-12.2-.1-18.3-.4l-.1 0h0c-4.6-.2-9.1-.4-13.7-.8C183.5 494.5 0 300.7 0 64v0C0 45.1 13.2 28.8 31.6 24.9l112-24c18.7-4 37.6 5.8 45.1 23.4l48 112c7 16.4 2.4 35.4-11.4 46.7l-40.6 33.2c26.7 46 65.1 84.4 111.1 111.1L329 286.7zM448 480c3.8 0 7-2.6 7.8-6.3l24-112c.8-3.7-1.2-7.5-4.7-9l-112-48c-3.3-1.4-7.1-.5-9.3 2.3l-33.2 40.6c-9.9 12.1-27.2 15.3-40.8 7.4c-50.9-29.5-93.3-71.9-122.7-122.7c-7.9-13.6-4.7-30.9 7.4-40.8l40.6-33.2c2.8-2.3 3.7-6.1 2.3-9.3l-48-112c-1.5-3.5-5.3-5.5-9-4.7l-112 24C34.6 57 32 60.2 32 64v0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0c0 229.6 186.1 415.8 415.7 416l.3 0z" fill="currentColor"></path></svg>
                            </div>
                        </div>
                        <h3 className='small-heading'>Secure Remote Access</h3>
                        <p>Integrate with popular voice assistants like Alexa or Google Assistant for hands-free control.</p>
                    </div>
                </div>
            </BoxContainer>
        </section>
    )
}

export default AccessControl