import React from 'react'
import './compatibility.css'
import BoxContainer from '../../components/boxContainer/BoxContainer'
import pic1 from '../../assets/w5S6MKI4S08wM30vGFBgN.jpg'
import pic2 from '../../assets/8zjxJilmpSp4uDzcvMGrS.jpg'

const Compatibility = () => {
    return (
        <section className='compatibility section-style box'>
            <BoxContainer>
                <h2 className='heading'>Compatibility with Smart Home Devices</h2>
                <div className='inner-container'>
                    <div className='box'>
                        <div className='image-container'>
                            <img src={pic1} alt='Smart Thermostat' />
                        </div>
                        <h3 className='small-heading'>Smart Hub integration</h3>
                        <p>Connect your thermostat to your smart home hub for seamless control and automation</p>
                    </div>
                    <div className='box'>
                        <div className='image-container'>
                            <img src={pic2} alt='Smart Thermostat' />
                        </div>
                        <h3 className='small-heading'>Voice Assistant Compatibility</h3>
                        <p>Control your thermostat with your voice using popular voice assistants like Alexa and Google Assistant.</p>
                    </div>
                </div>
            </BoxContainer>
        </section>
    )
}

export default Compatibility