import React from 'react'
import Container from '../../components/container/Container'
import './installation.css'
const Installation = () => {
    return (
        <section className='installation section-style'>
            <Container>
                <h2 className='heading'>Seamless Installation and Set-Up</h2>
                <div className='inner-container'>
                    <div className='box'>
                        <div className='step-number'><span>1</span></div>
                        <div className='box-content'>
                            <h3 className='small-heading'>Easy Installation</h3>
                            <p>Our thermostats are designed for simple installation, with clear instructions and minimal wiring.</p>
                        </div>
                    </div>
                    <div className='box'>
                        <div className='step-number'><span>2</span></div>
                        <div className='box-content'>
                            <h3 className='small-heading'>Quick Set-up</h3>
                            <p>Connect to your Wi-Fi and personalize settings within minutes, using our user-friendly app.</p>
                        </div>
                    </div>
                </div>
            </Container>
        </section>
    )
}

export default Installation