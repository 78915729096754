import  { initializeApp }  from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyBeA47Dn5nObm5sOsEHtMQatYdH9MvR3w8",
  authDomain: "thermostat-funnel.firebaseapp.com",
  projectId: "thermostat-funnel",
  storageBucket: "thermostat-funnel.firebasestorage.app",
  messagingSenderId: "49092778590",
  appId: "1:49092778590:web:160b8271d2dd074fa032cf",
  measurementId: "G-2ZHJQRFBZC"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig)
// export const firestore = getFirestore(firebaseConfig);
export const db = getFirestore(app);
export const auth = getAuth(app)
export default app