import React from 'react'
import './dashboard.css'
import Container from '../../components/container/Container'
import SubmissionsTable from '../../components/submissionsTable/SubmissionsTable'
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';
const Dashboard = () => {
  const { logout } = useAuth();
  let navigate = useNavigate();

  async function handleLogout() {
    try {
      await logout();
      navigate('/login');
    } catch (error) {
      console.error(error);
    }
  }
  return (
    <div className='dashboard-page'>
      <div>
        <Container>
          <h1 className='heading'>Dashboard</h1>
          <button onClick={handleLogout}>Logout</button>
          <SubmissionsTable/>
        </Container>
      </div>
    </div>
  )
}

export default Dashboard