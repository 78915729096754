import React from 'react'
import Container from '../../components/container/Container'

const NotFound = () => {
  return (
    <div>
        <Container>
            <h1 className='heading'>Page Not Found</h1>
            <p>Sorry, the page you're looking for doesn't exist.</p>
            <a href='/'>homepage</a>
        </Container>
    </div>
  )
}

export default NotFound