import React, { useState } from "react";
import BoxContainer from "../../components/boxContainer/BoxContainer";
import "./savingCalculator.css";


const SavingsCalculator = () => {
  const [energyBill, setEnergyBill] = useState("");
  const savingsPercentage = 30; // Fixed percentage for calculation

  const handleEnergyBillChange = (event) => {
    const value = event.target.value;
    setEnergyBill(value);
  };

  const calculateSavings = () => {
    const bill = parseFloat(energyBill);
    if (!bill || bill <= 0) return { savings: 0, newBill: 0 };

    const savings = (bill * savingsPercentage) / 100;
    const newBill = bill - savings;
    return { savings, newBill };
  };

  const { savings, newBill } = calculateSavings();

  return (
    <section className="calculate-savings section-style box">
        <BoxContainer>
          <h2 className="heading">Estimate Your Savings</h2>
          <div className="inner-container">
            <div className="box">
              <p>
              Discover how much you can save on energy bills by using our smart connected thermostat.   Simply enter your current energy consumption and expected savings percentage, and let our   calculator show you the difference our smart solutions can make.
              </p>
            </div>
            <div className="box">
              <input
                type="number"
                id="energyBill"
                placeholder="Ex: 1000"
                value={energyBill}
                onChange={handleEnergyBillChange}
              />

              {/* Calculation Output */}
              <div className="savings-output">
                <div className="inner-content">
                  <h4>Estimated Savings</h4>
                  <h4>{savings.toFixed(2)}$ (30%)</h4>
                </div>
                <div className="inner-content">
                  <h4>New Bill</h4>
                  <h4>{newBill.toFixed(2)}$</h4>
                </div>
              </div>

            </div>
          </div>

        </BoxContainer>
    </section>
  );
};

export default SavingsCalculator;
