import React from 'react'
import './testimonials.css'
import test1 from '../../assets/AQSNT4KhM9cYwu7KJHHIG.jpg'
import test2 from '../../assets/Di3hClWSq-qm0Evgsjgr6.jpg'
import test3 from '../../assets/w00m4xT6jhZctEub29i1Z.jpg'
import Container from '../../components/container/Container'

const Testimonials = () => {
    return (
        <section class="testimonial-section section-style">
            <Container>
                <h2 class="heading">What Our Customers Say</h2>
                <div class="testimonial-container">
                    {/* <!-- Testimonial 1 --> */}
                    <div class="testimonial">
                        <img src={test1} alt="Sarah M." class="testimonial-image"/>
                        <h3 class="testimonial-name">Sarah M.</h3>
                        <p class="testimonial-text">
                        I love the convenience and control of our smart thermostat. It's so easy to adjust the temperature from anywhere.
                        </p>
                    </div>
                    {/* <!-- Testimonial 2 --> */}
                    <div class="testimonial">
                        <img src={test2} alt="David S." class="testimonial-image"/>
                        <h3 class="testimonial-name">David S.</h3>
                        <p class="testimonial-text">
                        I've seen a significant decrease in my energy bills since installing this thermostat. It really works.
                        </p>
                    </div>
                    {/* <!-- Testimonial 3 --> */}
                    <div class="testimonial">
                        <img src={test3} alt="Emily K." class="testimonial-image"/>
                        <h3 class="testimonial-name">Emily K.</h3>
                        <p class="testimonial-text">
                        The smart features, like the scheduling and remote access, are game-changers. It's so much more convenient than my old thermostat.
                        </p>
                    </div>
                </div>
            </Container>
        </section>
    )
}

export default Testimonials