import { BrowserRouter as Router, Routes, Route} from 'react-router-dom'
import { AuthProvider } from './contexts/AuthContext'
import { Dashboard, Home, Login} from './pages/Index';
import './App.css';
import { HomePrivateRoute, LoginPrivateRoute } from './privateRoutes/Index';
import NotFound from './pages/notFound/NotFound';

function App() {
  return (
    <Router>
      <AuthProvider>
        <Routes>
          <Route exact path='/' element={<Home/>}/>
          <Route element={<HomePrivateRoute/>} >
            <Route exact path='/dashboard' element={<Dashboard/>}/>
          </Route>
          <Route element={< LoginPrivateRoute />} >
            <Route path="/login" element={<Login/>} />
          </Route>
          <Route path="*" element={<NotFound />} />
        </Routes>
      </AuthProvider>
    </Router>
  );
}

export default App;
