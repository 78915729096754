import React from 'react'
import './header.css'
import Container from '../../components/container/Container'

const Header = () => {
    return (
        <header>
            <Container>
                <h2 id='logo'>brand logo</h2>
                <a href='#request-form' className='gradient-link-button'>get yours now!</a>
            </Container>
        </header>
    )
}

export default Header