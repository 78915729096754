import React from "react";
import "./home.css"

import Testimonials from "../../sections/testimonials/Testimonials";
import Hero from "../../sections/Hero/Hero";
import EnergySaving from "../../sections/energySaving/EnergySaving";
import HowItWork from "../../sections/howItWork/HowItWork";
import SavingsCalculator from "../../sections/savingCalculator/SavingCalculator";
import Installation from "../../sections/installation/Installation";
import Scheduling from "../../sections/scheduling/Scheduling";
import AccessControl from "../../sections/accessControl/AccessControl";
import Compatibility from "../../sections/compatibility/Compatibility";
import MultiStepForm from "../../sections/multiStepForm/MultiStepFrom";
import Footer from "../../sections/footer/Footer";
import Header from "../../sections/header/Header";
const Home = () => {
  return (
    <div className="home">
      {/* Header Section */}
      <Header/>

      <Hero/>
      <Testimonials/>
      <HowItWork/>
      <SavingsCalculator/> 
      <Installation/>
      <Scheduling/>
      <AccessControl/>
      <EnergySaving/>
      <Compatibility/>
      <MultiStepForm/>

      {/* Footer */}
      <Footer/>
    </div>
  );
};

export default Home;
