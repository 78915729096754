import React from 'react'
import BoxContainer from '../../components/boxContainer/BoxContainer'
import './scheduling.css'

const Scheduling = () => {
  return (
    <section className="scheduling section-style box">
        <BoxContainer>
            <h2 className="heading">Personalized Temperature Scheduling</h2>
            <div class="steps">
                <div class="step">
                    <div id='pointer' class="step-header">
                        <svg data-testid="arrow-svg" class="css-17uqvha"><path d="
        M 327 0
        L 345 36
        L 327 72
        L 0 72
        L 18 36
        L 0 0 Z" class="themed-svg-shape-background css-0"></path></svg>
                        <div class="step-number"><span>1</span></div>
                    </div>
                    <div class="step-content">
                        <h2>Custom Schedules</h2>
                        <p>Create personalized schedules for different times of day and days of the week.</p>
                    </div>
                </div>
                <div class="step">
                <div id='pointer' class="step-header">
                        <svg data-testid="arrow-svg" class="css-17uqvha"><path d="
        M 327 0
        L 345 36
        L 327 72
        L 0 72
        L 18 36
        L 0 0 Z" class="themed-svg-shape-background css-0"></path></svg>
                        <div class="step-number"><span>2</span></div>
                    </div>
                    <div class="step-content">
                        <h2>Away Mode</h2>
                        <p>Automatically adjust temperatures when you're away, saving energy and money.</p>
                    </div>
                </div>
                <div class="step">
                <div id='pointer' class="step-header">
                        <svg data-testid="arrow-svg" class="css-17uqvha"><path d="
        M 327 0
        L 345 36
        L 327 72
        L 0 72
        L 18 36
        L 0 0 Z" class="themed-svg-shape-background css-0"></path></svg>
                        <div class="step-number"><span>3</span></div>
                    </div>
                    <div class="step-content">
                        <h2>Geolocation Features</h2>
                        <p>Use your smartphone's GPS to automatically adjust temperatures based on your location.</p>
                    </div>
                </div>
            </div>
        </BoxContainer>
    </section>
  )
}

export default Scheduling