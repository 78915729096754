import React, { useEffect, useState } from 'react';
import { collection, getDocs } from 'firebase/firestore';
import { db } from '../../firebase'; // Adjust the path to your firebase config
import './submissionsTable.css'

const SubmissionsTable = () => {
    const [submissions, setSubmissions] = useState([]);
    const [loading, setLoading] = useState(true);

    // Fetch submissions from Firebase
    useEffect(() => {
        const fetchSubmissions = async () => {
            try {
                const querySnapshot = await getDocs(collection(db, 'submissions'));
                const submissionsData = querySnapshot.docs.map((doc) => ({
                    id: doc.id,
                    ...doc.data(),
                }));
                setSubmissions(submissionsData);
            } catch (error) {
                console.error('Error fetching submissions:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchSubmissions();
    }, []);

    if (loading) {
        return <div>Loading...</div>;
    }

    return (
        <div className='submissions-table-container' >
            <div style={{ overflowX: 'auto' }}>
                <table >
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>Email</th>
                            <th>Phone</th>
                            <th>Address</th>
                            <th>Postal Code</th>
                            <th>Status</th>
                            <th>Created At</th>
                        </tr>
                    </thead>
                    <tbody>
                        {submissions.map((submission, index) => (
                            <tr className={index%2===0? 'pair': ''} key={submission.id}>
                                {/* <td style={styles.td}>{submission.submission_id}</td> */}
                                <td>{submission.formData.name}</td>
                                <td>{submission.formData.email}</td>
                                <td>{submission.formData.phone || 'N/A'}</td>
                                <td>{submission.formData.address || 'N/A'}</td>
                                <td>{submission.formData.postalCode || 'N/A'}</td>
                                <td>{submission.status}</td>
                                <td>{new Date(submission.createdAt?.seconds * 1000).toLocaleString()}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default SubmissionsTable;
