import React from 'react'
import './footer.css';
import Container from '../../components/container/Container';
const Footer = () => {
    let currentYear = new Date
    return (
        <footer className=''>
            <Container>
                <h3>
                    Developed by <a href='https://www.seifbadreddine.com' target='_blank'>seif badreddine</a>  &copy; { currentYear.getFullYear()} - All rights reserved.
                </h3>
            </Container>
        </footer>
    )
}

export default Footer