// to do: form field controls
import React, { useState } from "react";
import Container from '../../components/container/Container'
import './multiStepForm.css'
import { db } from '../../firebase';
import { addDoc, collection } from "firebase/firestore";


const MultiStepFrom = () => {
  const [currentStep, setCurrentStep] = useState(1);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [loading, setLoading] = useState(false); 
  const totalSteps = 3; 
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    address: "",
    region: "",
    postalCode: "",
  });

  const handleBack = () => {
    if (currentStep > 1) {
      setCurrentStep(currentStep - 1);
    }
  };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prev) => ({ ...prev, [name]: value }));
    };

    const validateStep = (step) => {
        switch (step) {
            case 1: // Step 1: Validate Name, Email, Phone
                if (!formData.name.trim()) {
                    alert('Name is required.');
                    return false;
                }
                if (!/^[a-zA-Z\s]+$/.test(formData.name)) {
                    alert('Please enter a valid name (letters and spaces only).');
                    return false;
                }
    
                if (!formData.email.trim()) {
                    alert('Email is required.');
                    return false;
                }
                if (!/^[\w.-]+@[a-zA-Z\d.-]+.[a-zA-Z]{2,}$/.test(formData.email)) {
                    alert('Please enter a valid email address.');
                    return false;
                }
    
                if (!formData.phone.trim()) {
                    alert('Phone number is required.');
                    return false;
                }
                if (!/^\+?\d{8,15}$/.test(formData.phone)) {
                    alert('Please enter a valid phone number (10-15 digits, optional +).');
                    return false;
                }
                break;
    
            case 2: // Step 2: Validate Address, Postal Code
                if (!formData.address.trim()) {
                    alert('Address is required.');
                    return false;
                }
                if (!/^[a-zA-Z0-9\s,.'-]{3,}$/.test(formData.address)) {
                    alert('Please enter a valid address (at least 3 characters).');
                    return false;
                }
    
                if (!formData.postalCode.trim()) {
                    alert('Postal code is required.');
                    return false;
                }
                if (!/^\d{4,6}$/.test(formData.postalCode)) {
                    alert('Please enter a valid postal code (4-6 digits).');
                    return false;
                }
                break;
    
            default:
                return true; // No validation for other steps
        }
        return true; // Validation passed
    };

    const clickHandler = async(e) => {
        e.preventDefault();
        if(currentStep!== totalSteps){
            if (!validateStep(currentStep)) {
                return; // Stop if validation fails
            }
            setCurrentStep(currentStep + 1);
        }else{
            setLoading(true);
            try {
                // Add submission with the incremented ID
                await addDoc(collection(db, 'submissions'), {
                    name: formData.name,
                    email: formData.email,
                    status: "pending",
                    createdAt: new Date(),
                    user_agent: navigator.userAgent,
                    formData: { ...formData }
                });
                setCurrentStep(currentStep + 1);
                setIsSubmitted(true); // Show success message
            } catch (error) {
                console.error('Error adding document: ', error);
            }finally {
                setLoading(false); // Hide loader after submission
            }

        }
    };

    return (
        <section id="request-form" class='multistep-form section-style'>
            <Container>
                <div class='inner-container'>
                    <div class='box image'>
                        {/* <img src={pic} alt="Smart Home Thermostat Multi-Step Form" class="image"/> */}
                    </div>
                    <div class='box form'>
                        <h2 class='heading'>Contact Us to Upgrade Your Home</h2>
                        <p>
                        Ready to experience the comfort and savings of a smart thermostat? Contact our team today for a free consultation and learn how we can optimize your home's climate control.
                        </p>
                        <div className="formbold-main-wrapper">
                            <div className="formbold-form-wrapper">
                                <div className="formbold-progress-bar-wrapper">
                                    <div
                                        className="formbold-progress-bar"
                                        style={{ width: `${100 - ((currentStep-1) / totalSteps) * 100}%` }}
                                    ></div>
                                </div>
                                
                                {
                                    !isSubmitted ? (
                                        <>
                                            {loading ? (
                                                <div className="loader">
                                                    <div className="loader-icon">
                                                        <svg 
                                                            width="120px" 
                                                            height="120px" 
                                                
                                                            viewBox="0 0 100 100" 
                                                            xmlns="http://www.w3.org/2000/svg"
                                                        >
                                                            <defs>
                                                                <linearGradient id="gradientLoader" x1="0%" y1="0%" x2="100%" y2="100%">
                                                                    <stop offset="0%" style={{ stopColor: '#A780FF' }} />
                                                                    <stop offset="100%" style={{ stopColor: '#FFA387' }} />
                                                                </linearGradient>
                                                            </defs>
                                                            <circle
                                                                cx="50"
                                                                cy="50"
                                                                r="45"
                                                                fill="none"
                                                                stroke="url(#gradientLoader)"
                                                                strokeWidth="8"
                                                                strokeLinecap="round"
                                                                strokeDasharray="280"
                                                                strokeDashoffset="0"
                                                            >
                                                                <animateTransform
                                                                    attributeType="XML"
                                                                    attributeName="transform"
                                                                    type="rotate"
                                                                    from="0 50 50"
                                                                    to="360 50 50"
                                                                    dur="1.5s"
                                                                    repeatCount="indefinite"
                                                                />
                                                            </circle>
                                                        </svg>
                                                    </div>
                                                </div>
                                            ):(
                                                <form>
                                                {/* Step 1 */}
                                                {currentStep === 1 && (
                                                    <div className="formbold-input-steps formbold-form-step-1 active">
                                                    <div>
                                                        {/* <label className="formbold-form-label">Name</label> */}
                                                        <input
                                                        type="text"
                                                        name="name"
                                                        value={formData.name}
                                                        onChange={handleChange}
                                                        placeholder="Name:"
                                                        className="formbold-form-input"
                                                        required
                                                        />
                                                    </div>
                                                    <div>
                                                        {/* <label className="formbold-form-label">Email</label> */}
                                                        <input
                                                        type="email"
                                                        name="email"
                                                        value={formData.email}
                                                        onChange={handleChange}
                                                        placeholder="Email:"
                                                        className="formbold-form-input"
                                                        required
                                                        />
                                                    </div>
                                                    <div>
                                                        {/* <label className="formbold-form-label">Phone Number</label> */}
                                                        <input
                                                        type="text"
                                                        name="phone"
                                                        value={formData.phone}
                                                        onChange={handleChange}
                                                        placeholder="Number:"
                                                        className="formbold-form-input"
                                                        required
                                                        />
                                                    </div>
                                                    </div>
                                                )}
            
                                                {/* Step 2 */}
                                                {currentStep === 2 && (
                                                    <div className="formbold-input-steps formbold-form-step-2 active">
                                                    <div>
                                                        {/* <label className="formbold-form-label">Address</label> */}
                                                        <input
                                                        type="text"
                                                        name="address"
                                                        value={formData.address}
                                                        onChange={handleChange}
                                                        placeholder="Address:"
                                                        className="formbold-form-input"
                                                        required
                                                        />
                                                    </div>
                                                    <div>
                                                        {/* <label className="formbold-form-label">Region</label> */}
                                                        <input
                                                        type="text"
                                                        name="region"
                                                        value={formData.region}
                                                        onChange={handleChange}
                                                        placeholder="Region:"
                                                        className="formbold-form-input"
                                                        required
                                                        />
                                                    </div>
                                                    <div>
                                                        {/* <label className="formbold-form-label">Postal Code</label> */}
                                                        <input
                                                        type="text"
                                                        name="postalCode"
                                                        value={formData.postalCode}
                                                        onChange={handleChange}
                                                        placeholder="Postal Code:"
                                                        className="formbold-form-input"
                                                        required
                                                        />
                                                    </div>
                                                    </div>
                                                )}
            
                                                {/* Step 3 */}
                                                {currentStep === 3 && (
                                                    <div className="formbold-input-steps formbold-form-step-3 active">
                                                    <p>This step is reserved for future alterations.</p>
                                                    </div>
                                                )}
            
                                                {/* Step 4 */}
                                                {/* {currentStep === 4 && (
                                                    <div className="formbold-input-steps formbold-form-step-4 active">
                                                    <h3>Confirm your details</h3>
                                                    <ul>
                                                        <li>Name: {formData.name}</li>
                                                        <li>Email: {formData.email}</li>
                                                        <li>Phone: {formData.phone}</li>
                                                        <li>Address: {formData.address}</li>
                                                        <li>Region: {formData.region}</li>
                                                        <li>Postal Code: {formData.postalCode}</li>
                                                    </ul>
                                                    <button type="submit" className="gradient-link-button">
                                                        Submit
                                                    </button>
                                                    </div>
                                                )} */}
                                                <div className="formbold-form-btn-wrapper">
                                                    {currentStep > 1 && (
                                                    <button type="button" className="gradient-link-button back-btn small active" onClick={handleBack}>
                                                        Back
                                                    </button>
                                                    )}
                                                    {/* {currentStep < 4 && ( */}
                                                    <button type="button" className="gradient-link-button small" onClick={clickHandler}>
                                                        {currentStep === totalSteps ? "submit" : "next step"}
                                                    </button>
                                                    {/* // )} */}
                                                </div>
                                                </form>
                                            )}
                                        </>
                                    ) : (
                                        <div className="success-message">
                                            <div className="success-icon">
                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                                    <defs>
                                                        <linearGradient id="myGradient2" x1="0%" y1="0%" x2="100%" y2="100%">
                                                            <stop offset="0%" style={{ stopColor: '#A780FF', stopOpacity: 1 }} />
                                                            <stop offset="50%" style={{ stopColor: '#F884BD', stopOpacity: 1 }} />
                                                            <stop offset="100%" style={{ stopColor: '#FFA387', stopOpacity: 1 }} />
                                                        </linearGradient>
                                                    </defs>
                                                    <path d="M256 48a208 208 0 1 1 0 416 208 208 0 1 1 0-416zm0 464A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM369 209c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0l-111 111-47-47c-9.4-9.4-24.6-9.4-33.9 0s-9.4 24.6 0 33.9l64 64c9.4 9.4 24.6 9.4 33.9 0L369 209z"/>
                                                </svg>
                                            </div>
                                            <h3 className="heading small-heading">Submitted Successfully!</h3>
                                        </div>
                                      )
                                }
                            </div>
                        </div>
  
                    </div>
                </div>
            </Container>
        </section>
    )   
}

export default MultiStepFrom